import React from 'react'

import RootLayout from '@components/rootLayout'
import RoutedLayout from '@src/components/routedLayout'
import SEO from '@src/components/seo'

export default () => (
  <RootLayout>
    <SEO />
    <RoutedLayout />
  </RootLayout>
)
